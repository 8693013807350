// @ts-expect-error: https://github.com/graphql/graphql-js/issues/2676
window.process ??= { env: '' };

import type { User } from '@auth0/auth0-spa-js';

import './polyfills';

import { authorize, logout, getAuth0Client } from '#auth0';

import * as Logger from '#helpers/logger';
// import { debugLogger } from '#helpers/debug-logging';
import { Role } from '#schema';

// import { DEBUG_LEVEL } from './app-config.json';

interface AuthState {
  authorized: boolean;
  loggingIn: boolean;
  roles: Role[];
  subscriptionPlan: string
}

interface AppState {
  hash: string;
  search: string;
  pathname: string;
}

export class Main {
  /** Dashboard skeleton loader. */
  declare private static fake: HTMLElement;

  /** Run when boot-up process completes. */
  declare private static onBoot: (authState: AuthState) => void;

  /** Used to validate that login succeeded. */
  declare private static user: User|void;

  /** App State passed to Auth0's loginWithRedirect. */
  declare private static appState: AppState;

  /** Partially applied log decorator. */
  private static log = (trace: string) => Logger.log({ prefix: 'boot', color: 'normal', trace });

  /** Flag set when Auth0 begins login process. When true, avoid redirecting to 404 */
  private static loggingIn = false;

  /** Promise that resolves when initial boot-up process completes. */
  public static bootComplete = new Promise<AuthState>(r => { Main.onBoot = r; });

  /** Navigate to a route */
  private static async go(route: Parameters<typeof import('@vaadin/router').Router['go']>[0]) {
    const { Router } = await import('@vaadin/router');
    await import('#router/effects');
    Router.go(route);
  }

  /**
   * Boot the app.
   */
  @Main.log('total boot up process')
  public static async run(): Promise<void> {
    this.initLoader();

    // Don't await this, instead run the boot sequence concurrently
    if (!'GRAPHQL_HOST'.includes('localhost'))
      this.initServiceWorker();

    const { pathname, search, hash } = window.location;
    const params = new URLSearchParams(search);

    try {
      // if the page has been redirected to from Auth0's login page
      if (params.has('state') && params.has('error') || params.has('code'))
        await this.handleAuthRedirect();
      else if (window['__PLAYWRIGHT_SCREENSHOT_TOKEN__'])
        this.setUserFromToken(window['__PLAYWRIGHT_SCREENSHOT_TOKEN__']);
      else
        await this.logIn({ pathname, search, hash });
      await this.initApollo();
      await this.initRouter();
      await this.initAppComponent().then(() => this.removeLoaderAndShowApp());
      await this.waitOnFonts();
    } catch (error) {
      this.handleBootError(error);
    } finally {
      this.onBoot({ authorized: !!this.user, loggingIn: this.loggingIn,
        roles: this.user?.['http://schemas.neuerenergy.com/identity/claims/role'],
        subscriptionPlan: this.user?.['http://schemas.neuerenergy.com/identity/claims/subscription_plan_name'] });
    }
  }

  /** Start the skeleton loader UI */
  private static initLoader(): void {
    this.fake = document.getElementById('fake-content');
    if (this.fake != null) {
      this.fake.querySelectorAll(':not(h1):not(svg)')
        .forEach((el: HTMLElement) => {
          el.style.setProperty('--random', `${Math.floor(Math.random() * 3 + 1)}`);
        });
    }
  }

  /** Log the user in */
  @Main.log('logging in')
  private static async logIn(appState: AppState): Promise<void> {
    this.loggingIn = true;
    this.user = await authorize({ appState });
    if (this.user)
      this.loggingIn = false;
  }

  /** handle Auth0 redirect from a previous login call */
  @Main.log('handling auth redirect')
  private static async handleAuthRedirect(): Promise<void> {
    try {
      const auth0 = await getAuth0Client();
      const result = await auth0.handleRedirectCallback();
      this.appState = result.appState;
      this.user = await auth0.getUser();
    } catch (error) {
      // SEE: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
      Logger.logger.log(`${Logger.logger.red('AUTH')} ${error.error}`);

      if ( error.message === 'user is blocked') {
        logout({
          openUrl: false,
        });
        this.go('/subscription-inactive');
      } else if (error.error === 'access_denied' || error.error === 'unauthorized') {
        logout({
          logoutParams: {
            localOnly: true,
          },
        });
        this.go('/unauthorized');
      } else if (error.message === 'Invalid authorization code' ||
      error.message === 'Invalid state') {
        logout();
        this.go('/sign-in');
      } else
        throw error;
    }
  }

  @Main.log('set user from token')
  private static setUserFromToken(token) {
    const [, base64Url] = token.split('.');
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
      `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
    const decoded = JSON.parse(jsonPayload);
    this.user = decoded;
  }

  /**
   * Try to register the service worker.
   */
  @Main.log('registering the service worker')
  private static async initServiceWorker(): Promise<void> {
    if ('serviceWorker' in navigator) {
      const { notifier } = await import('./register-sw');
      await notifier.register();
    }
  }

  /**
   * Try to boot the Apollo Client.
   * @param profile The user profile returned by Auth0, to be processed and stored in Apollo's local state.
   */
  @Main.log('loading the Apollo client')
  private static async initApollo(): Promise<void> {
    const { getApolloClient, loadUserProfile } = await import('#apollo/client');

    loadUserProfile(this.user);

    await getApolloClient();
  }

  /**
   * Loads some side effects the keep Apollo client in sync with the router.
   */
  @Main.log('initializing the router')
  private static async initRouter(): Promise<void> {
    await import('#router/effects');
    if (this.appState)
      this.go({ ...this.appState, pathname: this.appState.pathname ?? window.location.pathname });
  }

  /**
   * Try to load the app root component.
   */
  @Main.log('loading the app root')
  private static async initAppComponent(): Promise<void> {
    await import('#components/app');
  }

  /**
   * Waits until web fonts are loaded up before showing the page.
   */
  @Main.log('loading fonts')
  private static async waitOnFonts(): Promise<void> {
    await document.fonts?.ready ?? Promise.resolve();
  }

  /**
   * Finalizes the boot-up process by removing the fake skeleton loader.
   */
  private static removeLoaderAndShowApp(): void {
    this.fake.remove();
    document.querySelector('main').removeAttribute('loading');
  }

  /**
   * Logs any errors which occur during boot-up.
   */
  private static handleBootError(error: Error): void {
    Logger.logger.group(`${Logger.logger.blue('BOOT')} ${Logger.logger.red('ERROR')}`);
    Logger.logger.error(error);
    Logger.logger.groupEnd();
  }
}

Main.run();
