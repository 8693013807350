export const subscribeNetZeroLite = '/subscribe-net-zero-lite';
export const paymentSuccessNetZeroLite = '/payment-success';
export const onboardProfileNetZeroLite = '/onboard/profile';
export const onboardBaselineValuesNetZeroLite = '/onboard/baseline';

// Add anonymous routes into here to avoid sign in redirect on app startup
export const allAnonymousRoutes = [
  subscribeNetZeroLite,
  paymentSuccessNetZeroLite,
];
