import type {
  Auth0Client,
  RedirectLoginOptions,
  LogoutOptions,
  User,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';

import { createAuth0Client } from '@auth0/auth0-spa-js';

let auth0Client: Promise<Auth0Client>;

/**
 * We store the user's access token in memory only, as it is unsafe to
 * store is persistently (e.g. in localStorage or IDB)
 * @see https://auth0.com/docs/tokens/concepts/token-storage#single-page-app-scenarios
 */
let cachedToken: string = undefined;

export function getAuth0Client(): Promise<Auth0Client> {
  auth0Client ??= createAuth0Client({
    clientId: 'AUTH0_CLIENT_ID',
    domain: 'AUTH0_DOMAIN',
    authorizationParams: {
      audience: 'OPENIDCONNECTAUDIENCE',
      redirect_uri: window.location.origin,
    },
    legacySameSiteCookie: false,
    useRefreshTokens: false,
  });
  return auth0Client;
}

export async function resetPassword(): Promise<number> {
  const auth0Client = await getAuth0Client();

  if (await auth0Client.isAuthenticated()) {
    const user = await auth0Client.getUser();

    const response = await fetch(`https://AUTH0_DOMAIN/dbconnections/change_password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'email': user.email,
        'client_id': 'AUTH0_CLIENT_ID',
        'connection': 'Username-Password-Authentication',
      }),
    });

    return response.status;
  }
}

export async function getToken(): Promise<string> {
  if (window['__PLAYWRIGHT_SCREENSHOT_TOKEN__'])
    return window['__PLAYWRIGHT_SCREENSHOT_TOKEN__'];

  return cachedToken ??
    await auth0Client?.then(client => client.getTokenSilently());
}

export async function tryRefreshToken(options?: GetTokenSilentlyOptions): Promise<string | null> {
  const auth0 = await getAuth0Client();
  try {
    // First try refetching the token,
    const token = await auth0.getTokenSilently(options);
    cachedToken = token;
  } catch (error) {
    // otherwise, remove cached token on 401 from the server
    cachedToken = null;
  }

  return cachedToken;
}

/**
 * - If user is not logged in at all, redirects to login page
 * - Otherwise, the use is logged in - rehydrates the session.
 */
export async function authorize(opts?: RedirectLoginOptions): Promise<void|User> {
  const auth0 =
    await getAuth0Client();

  // User has no token stored: redirect to Auth0's hosted login page.
  if (!await auth0.isAuthenticated()) {
    const auth0 = await getAuth0Client();

    const { search, pathname, hash } =
    window.location;

    return await auth0.loginWithRedirect({
      ...opts,
      appState: {
        pathname: pathname === '/404' ? '/' : pathname,
        search,
        hash,
      },
    });
  // User already has a cookie from Auth0, continue booting app.
  } else
    return await auth0.getUser();
}

export async function logout(options?: LogoutOptions): Promise<void> {
  const auth0 = await getAuth0Client();
  return auth0.logout({
    ...options,
    logoutParams: {
      returnTo: options?.logoutParams?.returnTo ?? window.location.origin,
    },
  });
}
